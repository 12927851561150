import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Form,
  Header,
  Icon,
  Input,
  Label,
  List,
  ListItem,
  Message,
  MessageHeader,
  Modal,
  Popup,
  Segment,
} from "semantic-ui-react";
import { getZapierApiKey } from "../api/apps";
import {
  disconnectService,
  loginClockify,
  loginDynamics365,
} from "../api/auth";
import {
  addWhatsappNumber,
  getWhatsappConfig,
  updateWhatsappConfig,
} from "../api/whatsapp";
import CopyableText from "../components/CopyableText";
import PageLoader from "../components/PageLoader";
import config from "../config";
import { useAuth } from "../contexts/AuthContext";
import arborLogo from "../images/arbor-logo.png";
import asanaLogo from "../images/asana-logo.png";
import chromeWebStoreLogo from "../images/chrome-web-store-logo.png";
import clickupLogo from "../images/clickup-logo.png";
import clockifyLogo from "../images/clockify-logo.png";
import dynamics365Logo from "../images/dynamics365-logo.png";
import googleLogo from "../images/google-logo.png";
import hubspotLogo from "../images/hubspot-logo.png";
import microsoftLogo from "../images/microsoft-logo.png";
import mondayLogo from "../images/monday-logo.png";
import salesforceLogo from "../images/salesforce-logo.png";
import smokeballLogo from "../images/contact-provider-smokeball.png";
import trackerRmsLogo from "../images/tracker-rms-logo.png";
import vincereLogo from "../images/vincere-logo.png";
import whatsappLogo from "../images/whatsapp-logo.png";
import zapierLogo from "../images/zapier-logo.png";
import zohoDeskLogo from "../images/zoho-desk-logo.png";
import zohoLogo from "../images/zoho-logo.png";

const apps = [
  {
    integrationName: "google",
    featureName: "google",
    name: "Google",
    description:
      "Connect your Google account to search contacts, start a call from within Connect and see your calendar events",
    docsUrl: "https://docs.connect.cosoft.co.uk/apps/google",
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/google`}
        style={{ paddingTop: "0.5em" }}
      >
        <img src={googleLogo} alt="Google logo" />
        Sign in with Google
      </Label>
    ),
  },
  {
    integrationName: "microsoft",
    featureName: "microsoft",
    name: "Microsoft",
    description:
      "Connect your Microsoft account to search contacts, start a call from within Connect and see your calendar events",
    docsUrl: "https://docs.connect.cosoft.co.uk/apps/microsoft",
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/microsoft`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={microsoftLogo} alt="Microsoft logo" />
        Sign in with Microsoft
      </Label>
    ),
  },
  {
    integrationName: "arbor",
    featureName: "arbor",
    name: "Arbor",
    description: "Import your Arbor contacts and log calls and activities.",
    docsUrl: "",
    alwaysShow: true,
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/arbor`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={arborLogo} alt="Arbor logo" />
        Connect Arbor
      </Label>
    ),
  },
  {
    integrationName: "asana",
    featureName: "asana",
    name: "Asana",
    description: "View your assigned Asana tasks within Connect",
    docsUrl: "https://docs.connect.cosoft.co.uk/apps/asana",
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/asana`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={asanaLogo} alt="Asana logo" />
        Connect Asana
      </Label>
    ),
  },
  {
    integrationName: "clickup",
    featureName: "clickup",
    name: "ClickUp",
    description: "View your assigned ClickUp tasks within Connect",
    docsUrl: "https://docs.connect.cosoft.co.uk/apps/clickup",
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/clickup`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={clickupLogo} alt="Clickup logo" />
        Connect Clickup
      </Label>
    ),
  },
  {
    integrationName: "hubspot",
    featureName: "hubspot",
    name: "HubSpot",
    description:
      "Import contacts and users. Log calls as activities against contacts.",
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/hubspot`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={hubspotLogo} alt="HubSpot logo" />
        Connect HubSpot
      </Label>
    ),
  },
  {
    integrationName: "clockify",
    featureName: "clockify",
    name: "Clockify",
    description:
      "View your timers and stop your currently active timer within Connect",
    docsUrl: "https://docs.connect.cosoft.co.uk/apps/clockify",
    connectButton: <ClockifyIntegration />,
  },
  {
    integrationName: "click_to_dial",
    featureName: "click_to_dial",
    name: "Click to Dial",
    description: "Easily call numbers on any webpage with Connect for Chrome",
    docsUrl: "https://docs.connect.cosoft.co.uk/apps/chrome",
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`https://chrome.google.com/webstore/detail/connect-click-to-dial/joolkfmijoplolnckgdicbnijjjmhdlm?hl=en`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={chromeWebStoreLogo} alt="Chrome Web Store logo" />
        Chrome Web Store
      </Label>
    ),
  },
  {
    integrationName: "dynamics365",
    featureName: "dynamics365",
    name: "Dynamics365",
    description: "Enable contact searching and call logging with Dynamics365",
    docsUrl: "https://docs.connect.cosoft.co.uk/apps/dynamics365",
    connectButton: <Dynamics365Integration />,
    adminFeature: true,
  },
  {
    integrationName: "monday",
    featureName: "monday",
    name: "Monday",
    description: (
      <>
        <p>
          Search phone numbers and log calls as updates against Monday items.
        </p>
        <p>
          Before you can connect you must install the app within your space by{" "}
          <a
            href="https://auth.monday.com/oauth2/authorize?client_id=090cd5df07f69878b11a6d0000dc2386&response_type=install"
            target="_blank"
            rel="noreferrer"
          >
            clicking here.
          </a>
        </p>
      </>
    ),
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/monday`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={mondayLogo} alt="Monday logo" />
        Connect Monday
      </Label>
    ),
  },
  {
    integrationName: "salesforce",
    featureName: "salesforce",
    name: "Salesforce",
    description: "Search contacts. Log calls as events against contacts.",
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/salesforce`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={salesforceLogo} alt="Salesforce logo" />
        Connect Salesforce
      </Label>
    ),
  },
  {
    integrationName: "smokeball",
    featureName: "smokeball",
    name: "Smokeball",
    description: "Import your Smokeball contacts and log calls and activities.",
    docsUrl: "",
    alwaysShow: true,
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/smokeball`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={smokeballLogo} alt="Smokeball logo" />
        Connect Smokeball
      </Label>
    ),
  },
  {
    integrationName: "whatsapp",
    featureName: "whatsapp",
    name: "WhatsApp",
    description:
      "Configure WhatsApp integration for all users in your organisation who have access to it.",
    docsUrl: "https://docs.connect.cosoft.co.uk/apps/whatsapp/configuring",
    connectButton: <WhatsappIntegration />,
    adminFeature: true,
  },
  {
    integrationName: "zapier",
    featureName: "zapier",
    name: "Zapier",
    description: "Connect with a wide variety of existing integrations",
    connectButton: <ZapierIntegration />,
  },
  {
    integrationName: "vincere",
    featureName: "vincere",
    name: "Vincere",
    description:
      "Import candidates, contacts and users. Log calls as comments against candidates and contacts.",
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/vincere`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={vincereLogo} alt="Vincere logo" />
        Connect Vincere
      </Label>
    ),
  },
  {
    integrationName: "zoho",
    featureName: "zoho",
    name: "Zoho CRM",
    description:
      "Import contacts and leads from Zoho CRM. Log calls as activities against contacts and leads. Your Zoho account must be in the US or EU data centre.",
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/zoho`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={zohoLogo} alt="Zoho logo" />
        Connect Zoho CRM
      </Label>
    ),
  },
  {
    integrationName: "zoho-desk",
    featureName: "zoho-desk",
    name: "Zoho Desk",
    description:
      "Log calls as tickets against Zoho Desk contacts. Your Zoho account must be in the US or EU data centre.",
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/zoho-desk`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={zohoDeskLogo} alt="Zoho desk logo" />
        Connect Zoho Desk
      </Label>
    ),
  },
  {
    integrationName: "tracker-rms",
    featureName: "tracker-rms",
    name: "Tracker RMS",
    description:
      "Import contacts from Tracker RMS. Log calls against contacts.",
    connectButton: (
      <Label
        image
        size="big"
        as="a"
        color="blue"
        href={`${config.api_url}/login/tracker-rms`}
        style={{ paddingTop: "0.49em" }}
      >
        <img src={trackerRmsLogo} alt="Tracker RMS logo" />
        Connect Tracker RMS
      </Label>
    ),
  },
];

function Apps() {
  const { apiUser, hasFeature, hasIntegration } = useAuth();

  return (
    <Container>
      <Segment basic>
        <h1>Apps</h1>

        <Card.Group>
          {apps
            .filter(
              (x) =>
                (x.featureName === undefined ||
                  hasFeature([x.integrationName]) ||
                  x.alwaysShow) &&
                (!x.adminFeature || apiUser?.is_admin),
            )
            .sort((a, b) =>
              hasIntegration([a.integrationName]) >
              hasIntegration([b.integrationName])
                ? -1
                : 1,
            )
            .map((app) => (
              <AppCard
                key={app.integrationName}
                connected={hasIntegration([app.integrationName])}
                {...app}
              />
            ))}
        </Card.Group>
      </Segment>
    </Container>
  );
}

function AppCard({
  integrationName,
  name,
  description,
  docsUrl,
  connected,
  connectButton,
}) {
  const { setApiUser } = useAuth();
  const [isHovering, setIsHovering] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);

  const handleDisconnect = async () => {
    setDisconnecting(true);
    try {
      const res = await disconnectService(integrationName);
      setApiUser(res.data.data);
      toast("Successfully disconnected", { type: "success" });
    } catch (e) {
      toast("Failed to disconnect", { type: "error" });
    }
    setDisconnecting(false);
  };

  return (
    <Card
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        scale: isHovering ? "1.05" : "1",
        transition: "all 100ms ease-in-out",
      }}
    >
      <Card.Content>
        <Card.Header>
          {connected && <Icon name="check circle" color="green" />} {name}
        </Card.Header>
        <p style={{ marginTop: "12px" }}>{description}</p>

        {docsUrl && (
          <a href={docsUrl} target="_blank" rel="noreferrer">
            Learn more
          </a>
        )}
      </Card.Content>
      <Card.Content extra>
        <div style={{ marginTop: "8px" }}>
          {connected ? (
            <Button
              color="red"
              onClick={handleDisconnect}
              disabled={disconnecting}
              loading={disconnecting}
            >
              Disconnect
            </Button>
          ) : (
            <>{connectButton}</>
          )}
        </div>
      </Card.Content>
    </Card>
  );
}

function ClockifyIntegration() {
  const { setApiUser, hasIntegration } = useAuth();
  const [open, setOpen] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disconnecting, setDisconnecting] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    loginClockify({ clockify_api_key: apiKey })
      .then((res) => {
        toast("Successfully connected", { type: "success" });
        setApiKey("");
        setApiUser(res.data.data);
        setOpen(false);
      })
      .catch((res) => setError(res.response.data.message))
      .finally(() => setLoading(false));
  };

  const handleDisconnect = async () => {
    setDisconnecting(true);
    try {
      const res = await disconnectService("clockify");
      setApiUser(res.data.data);
      toast("Successfully disconnected", { type: "success" });
    } catch (e) {
      toast("Failed to disconnect", { type: "error" });
    }
    setDisconnecting(false);
  };

  return (
    <>
      {hasIntegration(["clockify"]) ? (
        <Button
          primary
          onClick={handleDisconnect}
          disabled={disconnecting}
          loading={disconnecting}
        >
          Disconnect Clockify
        </Button>
      ) : (
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={
            <Label
              image
              size="big"
              as="a"
              color="blue"
              style={{ paddingTop: "0.49em" }}
            >
              <img src={clockifyLogo} alt="Clockify logo" />
              Connect Clockify
            </Label>
          }
        >
          <Modal.Header>Clockify Integration</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header>Retrieving your API key</Header>
              <p>You will need to get your personal Clockify API key.</p>
              <p>
                To do this, navigate to Clockify and select your user avatar in
                the top right, and click profile settings.
              </p>
              <p>
                Toward the bottom of this page you should see an "API" section.
                Generate a key and enter in the text field below.
              </p>

              <Form>
                <Form.Field>
                  <input
                    placeholder="Enter Clockify API key here"
                    value={apiKey}
                    onChange={(e) => {
                      setError("");
                      setApiKey(e.target.value.trim());
                    }}
                  />
                </Form.Field>

                {error.length > 0 && (
                  <Message negative>
                    <p>{error}</p>
                  </Message>
                )}
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              onClick={() => setOpen(false)}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              content="Save"
              labelPosition="right"
              icon="checkmark"
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
              positive
            />
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
}

function WhatsappIntegration() {
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [appSecret, setAppSecret] = useState("");
  const [whatsappBusinessId, setWhatsappBusinessId] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const isFormModified = () => {
    return apiKey !== "" || appSecret !== "" || whatsappBusinessId !== "";
  };

  const loadConfig = async () => {
    setLoading(true);
    const config = await getWhatsappConfig();
    setConfig(config?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      loadConfig();
    }
  }, [open]);

  const handleSubmit = () => {
    setSubmitting(true);
    updateWhatsappConfig({
      api_key: apiKey,
      app_secret: appSecret,
      whatsapp_business_id: whatsappBusinessId,
    })
      .then(() => {
        toast("Configuration updated", { type: "success" });
        setApiKey("");
        setAppSecret("");
        setWhatsappBusinessId("");
        setError("");
        setShowForm(false);
        loadConfig();
      })
      .catch((res) => setError(res.response.data.message))
      .finally(() => setSubmitting(false));
  };

  const handleAddNumber = (phoneNumberId, phoneNumber) => {
    setSubmitting(true);
    addWhatsappNumber({
      phone_number_id: phoneNumberId,
      phone_number: phoneNumber,
    })
      .then(() => {
        toast("Phone number added", { type: "success" });
        setError("");
        loadConfig();
      })
      .catch((res) => setError(res.response.data.message))
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Modal
        onClose={() => {
          setShowForm(false);
          setError("");
          setAppSecret("");
          setApiKey("");
          setWhatsappBusinessId("");
          setOpen(false);
        }}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={
          <Label
            image
            size="big"
            as="a"
            color="blue"
            style={{ paddingTop: "0.49em" }}
          >
            <img src={whatsappLogo} alt="WhatsApp logo" />
            Configure WhatsApp
          </Label>
        }
      >
        <Modal.Header>WhatsApp integration</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {loading ? (
              <PageLoader>Loading...</PageLoader>
            ) : (
              <>
                <Header>Step 1: Set up webhooks</Header>
                <p>
                  If you haven't set up a WhatsApp app in your Meta developer
                  console yet, you should do that first.
                </p>
                <p>
                  The next step is to configure webhooks in the Meta developer
                  console. You'll need to enter these details:
                </p>
                <p>
                  <strong>Callback URL:</strong>{" "}
                  <CopyableText text={config?.callback_url} />
                </p>
                <p>
                  <strong>Verify token:</strong>{" "}
                  <CopyableText text={config?.validation_token} />
                </p>
                <hr />
                <Header>Step 2: Enter API details</Header>
                {config?.is_configured ? (
                  <>
                    <p>
                      <strong>
                        You've already configured WhatsApp. If you change the
                        configuration here it may disconnect your current
                        WhatsApp integration.
                      </strong>
                    </p>
                    <p>
                      You don't need to re-enter all of the details to change
                      the configuration &ndash; just complete the fields you
                      want to change and leave the others blank to retain their
                      previously-configured values.
                    </p>
                  </>
                ) : (
                  <p>
                    Once you've successfully completed step 1, you'll need to
                    get some details from the Meta developer console to enter
                    into Connect.
                  </p>
                )}
                {!config?.is_configured || showForm ? (
                  <Form>
                    {error.length > 0 && (
                      <Message negative>
                        <p>{error}</p>
                      </Message>
                    )}
                    <Form.Field>
                      <label>API Key</label>
                      <input
                        placeholder={
                          config?.is_configured
                            ? "******************"
                            : "Enter an API key"
                        }
                        value={apiKey}
                        onChange={(e) => {
                          setError("");
                          setApiKey(e.target.value.trim());
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>App Secret</label>
                      <input
                        placeholder={
                          config?.is_configured
                            ? "************"
                            : "Enter an App secret"
                        }
                        value={appSecret}
                        onChange={(e) => {
                          setError("");
                          setAppSecret(e.target.value.trim());
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>WhatsApp Business ID</label>
                      <input
                        placeholder={
                          config?.is_configured
                            ? "********"
                            : "Enter a WhatsApp Business ID"
                        }
                        value={whatsappBusinessId}
                        onChange={(e) => {
                          setError("");
                          setWhatsappBusinessId(e.target.value.trim());
                        }}
                      />
                    </Form.Field>
                  </Form>
                ) : (
                  <Button color="blue" onClick={() => setShowForm(true)}>
                    Change configuration
                  </Button>
                )}
                {config?.is_configured && (
                  <>
                    <hr style={{ marginTop: "25px" }} />
                    <Header>Step 3: Add phone numbers</Header>
                    <p>
                      Once you add a phone number in the Meta developer portal
                      you will be able to add it to Connect.
                    </p>
                    {config?.active_numbers?.length > 0 && (
                      <>
                        <Header as="h3">Added to Connect</Header>
                        <List celled>
                          {config?.active_numbers?.map((number) => {
                            return (
                              <ListItem
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "0.5em",
                                }}
                              >
                                {number.phone_number
                                  ? `${number.phone_number} (ID: ${number.phone_number_id})`
                                  : `Phone number ID: ${number.phone_number_id}`}
                                {!number.is_verified &&
                                  !config?.warnings.length > 0 && (
                                    <Popup
                                      position="bottom center"
                                      content={"Pending WhatsApp verification"}
                                      trigger={
                                        <Icon
                                          size="small"
                                          name="hourglass half"
                                          style={{ margin: "0px 10px" }}
                                        />
                                      }
                                    />
                                  )}
                                {!number.in_whatsapp && (
                                  <Popup
                                    position="bottom center"
                                    content={
                                      "This number is active in Connect but we couldn't verify it with WhatsApp. Check that it's correctly set up, and that there are no warnings below about your WhatsApp configuration."
                                    }
                                    trigger={
                                      <Icon
                                        size="small"
                                        color="orange"
                                        name="exclamation triangle"
                                        style={{ margin: "0px 5px" }}
                                      />
                                    }
                                  />
                                )}
                              </ListItem>
                            );
                          })}
                        </List>
                      </>
                    )}
                    {config?.warnings?.length > 0 ? (
                      <Message negative>
                        <MessageHeader>
                          Unable to get your registered numbers from WhatsApp
                        </MessageHeader>
                        {config?.warnings.map((warning, index) => (
                          <p>{warning}</p>
                        ))}
                      </Message>
                    ) : (
                      config?.available_numbers?.length > 0 && (
                        <>
                          <Header as="h3">Ready to add</Header>
                          <List celled>
                            {config?.available_numbers?.map((number) => {
                              return (
                                <ListItem style={{ padding: "0.5em" }}>
                                  <Button
                                    inverted
                                    size="tiny"
                                    circular
                                    color="green"
                                    icon="plus circle"
                                    style={{ marginRight: "10px" }}
                                    onClick={() =>
                                      handleAddNumber(
                                        number.phone_number_id,
                                        number.phone_number,
                                      )
                                    }
                                  />
                                  {number.phone_number}
                                </ListItem>
                              );
                            })}
                          </List>
                        </>
                      )
                    )}
                  </>
                )}
              </>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              setShowForm(false);
              setError("");
              setAppSecret("");
              setApiKey("");
              setWhatsappBusinessId("");
              setOpen(false);
            }}
            disabled={submitting}
          >
            {isFormModified() ? "Cancel" : "Close"}
          </Button>
          {isFormModified() && (
            <Button
              content="Save"
              labelPosition="right"
              icon="checkmark"
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit}
              positive
            />
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
}

function ZapierIntegration() {
  const [open, setOpen] = useState(false);
  const [apiKeyVisible, setApiKeyVisible] = useState(false);

  const zapierQuery = useQuery(
    ["zapier-api-key"],
    () => {
      return getZapierApiKey();
    },
    {
      enabled: open,
    },
  );

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Label
          image
          size="big"
          as="a"
          color="blue"
          style={{ paddingTop: "0.49em" }}
        >
          <img src={zapierLogo} alt="Zapier logo" />
          Connect Zapier
        </Label>
      }
    >
      <Modal.Header>Zapier Integration</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>Your Connect API key for Zapier is...</p>

          <Input
            style={{ width: "100%", marginBottom: "16px" }}
            loading={zapierQuery.isFetching}
            readonly
            icon={{
              name: apiKeyVisible ? "eye slash" : "eye",
              link: true,
              onClick: () => setApiKeyVisible(!apiKeyVisible),
            }}
            placeholder="Loading..."
            type={apiKeyVisible ? "text" : "password"}
            value={zapierQuery.data?.data?.data?.api_key}
          />

          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://zapier.com/developer/public-invite/191635/b920667e9e121f0732e2de15b07d311f/"
            >
              To get started go to Connect for Zapier
            </a>
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function Dynamics365Integration() {
  const { setApiUser, hasIntegration } = useAuth();
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [contactsAppId, setContactsAppId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disconnecting, setDisconnecting] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    loginDynamics365({
      url: url,
      contacts_app_id: contactsAppId,
      tenant_id: tenantId,
      client_id: clientId,
      client_secret: clientSecret,
    })
      .then((res) => {
        toast("Successfully connected", { type: "success" });
        setUrl("");
        setContactsAppId("");
        setTenantId("");
        setClientId("");
        setClientSecret("");
        setApiUser(res.data.data);
        setOpen(false);
      })
      .catch((res) => setError(res.response.data.message))
      .finally(() => setLoading(false));
  };

  const handleDisconnect = async () => {
    setDisconnecting(true);
    try {
      const res = await disconnectService("dynamics365");
      setApiUser(res.data.data);
      toast("Successfully disconnected", { type: "success" });
    } catch (e) {
      toast("Failed to disconnect", { type: "error" });
    }
    setDisconnecting(false);
  };

  return (
    <>
      {hasIntegration(["dynamics365"]) ? (
        <Button
          primary
          onClick={handleDisconnect}
          disabled={disconnecting}
          loading={disconnecting}
        >
          Disconnect Dynamics
        </Button>
      ) : (
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={
            <Label
              image
              size="big"
              as="a"
              color="blue"
              style={{ paddingTop: "0.49em" }}
            >
              <img src={dynamics365Logo} alt="Dynamics365 logo" />
              Connect Dynamics
            </Label>
          }
        >
          <Modal.Header>Dynamics365 Integration</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>
                If you do not have the below information you can{" "}
                <a
                  href="https://docs.connect.cosoft.co.uk/apps/dynamics365"
                  target="_blank"
                  rel="noreferrer"
                >
                  follow the guide here to retrieve it
                </a>
                .
              </p>

              <Form>
                <Form.Field>
                  <input
                    placeholder="Enter Dynamics365 URL"
                    value={url}
                    onChange={(e) => {
                      setError("");
                      setUrl(e.target.value.trim());
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <input
                    placeholder="Enter Dynamics365 contacts app ID"
                    value={contactsAppId}
                    onChange={(e) => {
                      setError("");
                      setContactsAppId(e.target.value.trim());
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <input
                    placeholder="Enter Microsoft app registration tenant ID"
                    value={tenantId}
                    onChange={(e) => {
                      setError("");
                      setTenantId(e.target.value.trim());
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <input
                    placeholder="Enter Microsoft app registration client ID"
                    value={clientId}
                    onChange={(e) => {
                      setError("");
                      setClientId(e.target.value.trim());
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <input
                    placeholder="Enter Microsoft app registration client secret"
                    value={clientSecret}
                    type="password"
                    onChange={(e) => {
                      setError("");
                      setClientSecret(e.target.value.trim());
                    }}
                  />
                </Form.Field>

                {error.length > 0 && (
                  <Message negative>
                    <p>{error}</p>
                  </Message>
                )}
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              onClick={() => setOpen(false)}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              content="Save"
              labelPosition="right"
              icon="checkmark"
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
              positive
            />
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
}

export default Apps;
